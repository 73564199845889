<template lang="pug">
.d-flex.justify-content-between.justify-content-sm-start.align-items-center.flex-row(v-if="shouldDisplayTriptypeFilters")
  button.filter-bar__type__btn.btn.rounded-4.py-1.py-sm-2.px-3.font-weight-normal.text-transform-none.d-flex.flex-column.flex-sm-row.align-items-center.mb-1(
    :class="getFilterButtonClass('all')",
    data-filter="all-trip-types",
    @click="setAll"
  )
    span.nowrap.pl-sm-1
      fa-layers
        fa(icon="plane" transform="shrink-6 up-4 right-5")
        fa(icon="bus" transform="shrink-6 down-4 left-5" opacity)
    screenWidthProvider(v-slot="{ isWider }")
      span.d-block.d-sm-inline-block.ml-0.ml-sm-2.pl-1(v-if="(isWider('sm'))" data-i18n="calendar.allTypes") {{ $t('calendar.allTypes') }}
      span.d-block.d-sm-inline-block.ml-0.ml-sm-2.pl-1(v-else data-i18n="calendar.mobileAllTypes") {{ $t('calendar.mobileAllTypes') }}

  button.filter-bar__type__btn.btn.rounded-4.py-1.py-sm-2.px-3.font-weight-normal.text-transform-none.d-flex.flex-column.flex-sm-row.align-items-center.mb-1(
    :class="getFilterButtonClass('bus')",
    data-filter="only-bus-trips",
    @click="setBus"
  )
    fa.d-sm-inline-block(icon="bus")
    span.d-block.d-sm-inline-block.ml-0.ml-sm-2(data-i18n="calendar.onlyBus") {{ $t('calendar.onlyBus') }}

  button.btn.rounded-4.py-1.py-sm-2.px-3.font-weight-normal.text-transform-none.d-flex.align-items-center.flex-column.flex-sm-row.mb-1(
    v-if="isOnlyFlightButton"
    :class="getFilterButtonClass('flight')"
    @click="setFilterFlight()",
  )
    fa.d-inline-block(icon="plane")
    span.d-block.d-sm-inline-block.ml-0.ml-sm-2(data-i18n="calendar.onlyFlights") {{ $t('calendar.onlyFlights') }}

  Dropdown.filter-bar__type__btn.mb-1(
    v-else
    ref="airportSelect"
    data-filter="only-flight-trips"
    close-on-click
  )
    template(#trigger="{ toggle }")
      .btn-group.w-100.h-100
        button.btn.rounded-4.py-1.py-sm-2.px-3.font-weight-normal.text-transform-none.d-flex.align-items-center.flex-column.flex-sm-row(
          :class="getFilterButtonClass('flight')"
          type="button"
          @click="toggle",
        )
          fa.d-inline-block(icon="plane")
          span.d-block.d-sm-inline-block.ml-0.ml-sm-2(data-i18n="calendar.onlyFlights") {{ displayFilterFlight }}
    template(#content)
      .w-100.bg-white
        button.dropdown-item(
          v-for="(option, index) in airportList",
          :key="index",
          type="button",
          @click="setFilterFlight(option.iata)"
        ) {{ option.name }}
</template>

<script>
import { mapState, mapActions } from 'pinia'
import events from '@layers/web/constants/events.js'
import { TRIP_TYPES } from '@packages/trip/constants/types.ts'

export default defineNuxtComponent({
  props: {
    airports: {
      type: Array,
      default: () => [],
    },

    darkFilter: {
      type: Boolean,
      default: false,
    },

    vertical: {
      type: Boolean,
      default: false,
    },

    isOnlyFlightButton: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['filter', 'update', 'airport', events.selectedAirport],

  data () {
    return {
      TRIP_TYPES,
    }
  },

  computed: {
    ...mapState(useCalendarStore, {
      filterTripType: 'filterTripType',

      filters: 'filters',
      filterDuration: 'filterDuration',
      filterLastMinute: 'filterLastMinute',
      selectedStartDateStore: 'selectedStartDate',
    }),

    ...mapState(useLocaleStore, ['locale']),

    airportList () {
      return (this.airports || [])
        .map(x => ({
          ...x,
          iata: x?.iata || x?.code || x?.value,
          name: x?.name,
        }))
        .filter(x => x?.iata)
    },

    displayFilterFlight () {
      if (
        this.filterTripType === TRIP_TYPES.flight &&
        this.filters?.airport &&
        this.filters.airport !== TRIP_TYPES.all
      ) {
        const ap = this.airportList.find(o => o.iata === this.filters.airport)
        return ap?.name || this.$t('calendar.onlyFlights')
      }

      return this.$t('calendar.onlyFlights')
    },

    shouldDisplayTriptypeFilters () {
      if (this.locale !== 'no') {
        return true
      }

      return false
    },
  },

  watch: {
    filterTripType (v) {
      if (v === TRIP_TYPES.flight) {
        this.setFilter({ filter: 'onlyflight', value: 1 })
        this.setFilter({ filter: 'onlybus', value: 0 })
        return
      }

      this.setFilter({ filter: 'airport', value: TRIP_TYPES.all })

      if (v === TRIP_TYPES.bus) {
        this.setFilter({ filter: 'onlyflight', value: 0 })
        this.setFilter({ filter: 'onlybus', value: 1 })
      } else {
        this.setFilter({ filter: 'onlyflight', value: 0 })
        this.setFilter({ filter: 'onlybus', value: 0 })
      }

      this.$emit('filter')
    },
  },

  methods: {
    ...mapActions(useCalendarStore, [
      'setFilter',
      'setTripType',
    ]),

    updateType (type, iata = null) {
      const airport = !iata || iata === TRIP_TYPES.all ? null : iata

      this.setTripType(type)
      this.setFilter({ filter: 'onlyflight', value: type === TRIP_TYPES.flight ? 1 : 0 })
      this.setFilter({ filter: 'onlybus', value: type === TRIP_TYPES.bus ? 1 : 0 })
      this.setFilter({ filter: 'airport', value: iata || 'all' })

      this.$emit('update', { type, airport })
      this.setAirport(airport)
    },

    setFilterFlight (iata) {
      this.updateType(TRIP_TYPES.flight, !iata || iata === TRIP_TYPES.all ? null : iata)
    },

    setAll () {
      this.updateType(TRIP_TYPES.all)
    },

    setBus () {
      this.updateType(TRIP_TYPES.bus)
    },

    setAirport (value) {
      this.$event.$emit(events.selectedAirport, value)
      this.$emit('airport', value)
      this.$emit('filter')
    },

    getFilterButtonClass (filterName) {
      const classes = []

      if (this.filterTripType === TRIP_TYPES[filterName]) {
        if (this.darkFilter) {
          classes.push('bg-black text-white')
        } else {
          classes.push('bg-medium-blue text-white')
        }
      } else if (this.darkFilter) {
        classes.push('bg-black-blue-transparent text-white')
      } else {
        classes.push('bg-white-blue text-black')
      }

      if (!this.vertical) {
        classes.push('mr-1 mr-md-3')
      }

      return classes.join(' ')
    },
  },
})
</script>

<style lang="scss">
.filter-bar__type__btn {
  min-width: 120px;

  @media all and (max-width:$sm) {
    min-width: 0;
    width: calc(1 / 3 * 100%);
  }
}
</style>
